/* global grecaptcha */
import React, { Component } from "react";
import { numberWithSpaces, withRouter, API_KEY } from "../GlobalFunctions"
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Collapse, Checkbox } from 'antd'; 

const antIcon = ( <LoadingOutlined style={{ fontSize: 24, }} spin /> );

export class Rexgen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            requestFailed: false,
            data: null,
            token: "",
            verified: false,
            evidenceView: false,
            allTickets: null,
            selectedTickets: null,
            isAgreed: false,
        };
    };

    componentDidMount() {
        this.getData();
    }; 

    componentDidUpdate() {
    
    };

    getData = () => {
        fetch('https://smartpay.tj/subapi/payler/safecity/search/' + this.props.params.plateNumber, 
        { 
            method: "GET", 
            headers : {
                'Content-Type': 'application/json; charset=utf-8',
                'Accept': 'application/json',
                'x-app-token': API_KEY(),
            }
        })
    
        .then((response) => {
          if(response.status === 200){
              return response.json();     
          }else {
              this.setState({ requestFailed: true })
              window.location.href = 'https://smartpay.tj/'
          }
        })
            .then((responseJson) => {
              this.setState({ data : responseJson, 
                allTickets : responseJson.tickets, 
                selectedTickets : responseJson.tickets, 
                isLoaded: true });
            });
      };

    onTicketView = (value) => {
        if (value !== null){
            window.location.href = `/violations/${this.props.params.plateNumber}/evidence/${value}`;
        }
    };

    handleVerify = async () => {
        try {
          const token = await grecaptcha.execute("6LdbjS0qAAAAAFrFSN5HHvRkffPfeG-078Hbadb_", { action: "submit" });
          //console.log("Полученный токен:", token);
          this.setState({ token }, this.submitForm);
        } catch (error) {
          //console.error("Ошибка при вызове ReCaptcha:", error);
        }
      };
    
    handleAgreementChange = () => {
        this.setState((prevState) => ({
          isAgreed: !prevState.isAgreed,
        }));
    };

    calculateSum=(tickets)=>{
        return tickets.reduce((total, item) => total + (item.amount || 0), 0);
    };

    onCheckBoxChange = (e) => {
        //console.log(e.target.checked, e.target.value, this.state.selectedTickets);
        const id = e.target.value
        this.setState((prevState) => {
            const { selectedTickets } = prevState;
      
            if (!e.target.checked) {
              // Удаляем ID, если он уже выбран
              return {
                selectedTickets: selectedTickets.filter((item, _) => item.id !== id),
              };
            } else {
              // Добавляем ID, если он не выбран
              return {
                selectedTickets: [...selectedTickets, this.state.allTickets.filter((item, _) => item.id === id)[0]],
              };
            }
          });

    };

    submitForm = async () => {
        this.setState({isLoaded: false});
        const { token } = this.state;
        const response = await fetch('https://smartpay.tj/subapi/payler/verify-recaptcha', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }),
        });
    
        const result = await response.json();
        if (result.success) {
            let val = {
                'Плательщик': this.props.params.plateNumber, 
                'РМА': '020055937',
                'Рақами бақайдгирии воситаи нақлиёт': this.props.params.plateNumber
            };

            fetch('https://smartpay.tj/subapi/payler/safecity/create', {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  'x-app-token': API_KEY(),
                },
                body: JSON.stringify({
                    'form_id': 510,
                    'bill_to': val,
                    'customer_phonenumber': '000000000', 
                    'services': this.state.selectedTickets
                })
              }).then((response) => {
                if(response.status === 200){
                    return response.json();     
                }else {
                    this.setState({ requestFailed: true, isLoaded: true })
                    alert('Дархост қайд нашуд');
                }
              }).then((responseJson) => {
                    window.location.href = `https://smartpay.tj/invoice/${responseJson.invoice_number}`
              });
        } else {
          this.setState({isLoaded: true});
          alert('Verification failed');
        }
      };

render () {
    const { isLoaded, data, selectedTickets, allTickets, isAgreed } = this.state;
    const { translation } = this.props;
    const { Panel } = Collapse;
    
return (
    <div className="flex justify-center w-full text-center pt-4">{!isLoaded ?
      <div className="flex w-full h-[80vh] justify-center items-center">
        <Spin size="large" indicator={antIcon} />
      </div> : <>
      {!data.message ? 
      <div className="sm:w-full md:w-[60%]">
            <div className="w-full flex justify-center sm:text-[14px] md:text-[18px]">{translation('vehicle_plate')}</div>
            <div className="w-full flex justify-center py-4">
                <div className="rounded-[5px] border-[2px] border-black px-4">    
                    <div className="text-[24px] font-bold tracking-wider">{this.props.params.plateNumber}</div>
                </div>
            </div>
            <div className="w-full text-center sm:text-[14px] md:text-[18px] sm:px-2">{translation('violation')} : {translation('rexgen_violation')}</div>
            <div className="flex w-full sm:px-4 pt-2 justify-center font-semibold">
                <div className="border-l-[1px] border-t-[1px] border-slate-500 w-full px-2 text-center">{translation('violation_qty')}</div>
                <div className="border-l-[1px] border-r-[1px] border-t-[1px] border-slate-500 w-full px-2 text-center">{translation('violation_amount')}</div>
            </div>
            <div className="flex w-full sm:px-4 pb-2 justify-center font-semibold">
                <div className="py-2 border-l-[1px] border-t-[1px] border-b-[1px] border-slate-500 w-full px-2 text-center">{selectedTickets.length}</div>
                <div className="py-2 border-l-[1px] border-r-[1px] border-t-[1px] border-b-[1px] border-slate-500 w-full px-2 text-center">{numberWithSpaces(this.calculateSum(selectedTickets).toFixed(2))} сомони</div>
            </div>
            <div className="flex w-full justify-center py-4 px-8">

              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={isAgreed}
                  onChange={this.handleAgreementChange}
                />
                <span>Ман бо далелҳои вайрон кардани қоидаҳои ҳаракати роҳ, ки аз тарафи Низом сабт шудаанд, ва шартҳои пардохти ғайринақдӣ тавассути Smartpay розӣ ҳастам</span>
              </label>
            </div>
            <div className="flex w-full justify-center py-4">
              <button
                className={`
                  sm:w-[320px] 
                  md:w-[380px] 
                  flex 
                  justify-center 
                  items-center 
                  text-center 
                  text-white 
                  rounded-[10px] 
                  md:text-[20px] 
                  sm:text-[18px] 
                  font-semibold 
                  py-2 
                  px-8  
                  bg-emerald-600 
                  hover:bg-emerald-400 
                  hover:text-white 
                  ${!selectedTickets.length ? 'hidden' : ''}
                `}
                onClick={this.handleVerify}
                visible={!isAgreed}
              >
                {translation('pay_violation')}
              </button>
            </div>
            <div className="px-2">
            <Collapse defaultActiveKey={['1']}>
                <Panel header='Маълумоти муфассал' key="1">
                <div className="sm:px-[20px] sm:pb-[10px] mb-4 sm:pt-0 md:pt-[10px] text-slate-600">
                    <div className="flex w-full border-b-[1px] border-slate-600 font-semibold">
                        {allTickets.length > 10 ?
                            <div className="w-[20%] text-left">{translation('select')}</div>
                            :<></>
                        }               
                        <div className="w-[30%] text-center">{translation('DATE')}</div>
                        <div className="w-[30%] text-center">ID</div>
                        <div className="w-[20%] text-right">{translation('fine')}</div>
                    </div> 
                    {data.tickets.map((item, index)=>(
                    <div key={index} style={{cursor:"pointer"}} className="flex w-full border-b-[1px] border-slate-400 pt-2">
                        {allTickets.length > 10 ?
                        <div className="w-[20%] text-center">
                            <Checkbox defaultChecked onChange={this.onCheckBoxChange} value={item.id}></Checkbox>
                        </div>:<></>
                        }
                        <div className="w-[30%] text-center" >{`${item.date}`}</div>
                        <div className="w-[30%] text-center">{`${item.id}`}</div>
                        <div className="w-[20%] text-right" >{numberWithSpaces((item.amount).toFixed(2))}</div>
                    </div> 
                    )
                    )}
                </div>
                </Panel>
            </Collapse>
            </div>
      </div> : 
      <div>
        <div className="w-full flex justify-center sm:text-[14px] md:text-[18px]">{translation('vehicle_plate')}</div>
        <div className="w-full flex justify-center py-4">
            <div className="rounded-[5px] border-[2px] border-black px-4">    
                <div className="text-[24px] font-bold tracking-wider">{this.props.params.plateNumber}</div>
            </div>
        </div>
        <div className="w-full text-center sm:text-[14px] md:text-[18px] sm:px-2">{translation('no_violation')}</div>
      </div>
        }
    </>
    }</div>
);    
};
}

export default withRouter(Rexgen);