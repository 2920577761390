import React, { useState, useEffect } from "react";
import { Progress, Carousel, Card, Space } from "antd";
//import { MdOutlineAccountTree } from "react-icons/md";
//import { MdDisplaySettings } from "react-icons/md";
//import { RiSecurePaymentFill } from "react-icons/ri";
//import { HiOutlineArrowDown } from "react-icons/hi";
import { BiSolidMessageError, BiMessageRoundedError } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";



function Government(props) {
  const [percent, setPercent] = useState(20);
  const { t } = useTranslation();
  /*useEffect(() => {
    setTimeout(() => {
      const div2 = document.querySelector(".div2");
      const div3 = document.querySelector(".div3");
      const nextArrow = document.querySelector(".nextArrow");
      const nextArrow2 = document.querySelector(".nextArrow2");

      nextArrow.classList.remove("hidden");
      nextArrow.style.animation = "showInUp2 1s ease-in-out";

      setTimeout(() => {
        div2.classList.remove("hidden");
        div2.classList.add("flex");
        div2.style.animation = "slideInUp 1s ease-in-out";

        setTimeout(() => {
          nextArrow2.classList.remove("hidden");
          nextArrow2.style.animation = "showInUp2 1s ease-in-out";

          setTimeout(() => {
            div3.classList.remove("hidden");
            div3.classList.add("flex");
            div3.style.animation = "slideInUp 1s ease-in-out";
          }, 500);
        }, 1000);
      }, 500);
    }, 500);
  }, []);*/
  let state = 0;
  let state2 = 0;

  useEffect(() => {setInterval(() => {
    state = state + 1;
    nextCard(state);

    
      state2 = state2 + 1;
      nextCard2(state2);
    
    }, 1500)}
    ,[]);
  
  function nextCard2(i) {
    const slide_1 = document.querySelector('.slide_1');
    const slide_2 = document.querySelector('.slide_2');
    const slide_3 = document.querySelector('.slide_3');
    switch(i) {
      case 0:
        slide_1.classList.add("hidden");
        slide_2.classList.remove("hidden");
        slide_3.classList.add("hidden");
        //slide_2.style.animation = "showInUp2 0.25s ease-in-out";
        break;
      case 2:
        slide_1.classList.add("hidden");
        slide_2.classList.add("hidden");
        slide_3.classList.remove("hidden");
        //slide_3.style.animation = "showInUp2 0.25s ease-in-out";
        break;
      case 4:
        slide_1.classList.remove("hidden");
        slide_2.classList.add("hidden");
        slide_3.classList.add("hidden");
        //slide_1.style.animation = "showInUp2 0.25s ease-in-out";
        state2 = -2;
        break;
      default:
        break;
    }
  };

  function nextCard(i) {
    const myphone = document.querySelector(".myphone");
    const convert = document.querySelector(".convert");
    const sms = document.querySelector(".sms");
    const clickPart = document.querySelector(".clickPart");
    const smsBlock = document.querySelector(".messageBlock");
    const aboutPay = document.querySelector(".payThis");
    const clickBtn = document.querySelector(".clickBtn");
    const wallet = document.querySelector(".wallet");
    const payBtn = document.querySelector(".payBtn");
    const payedBlock = document.querySelector(".payedBlock");
    const payedText = document.querySelector(".payedText");
    
    switch (i){
      case 0:
        payedBlock.classList.add("hidden");
        payedText.classList.add("hidden");
        myphone.classList.add("phonebox");
        break;
      case 1:
        smsBlock.classList.remove("hidden");
        sms.classList.add("hidden");
        convert.classList.remove("hidden");
        convert.style.animation = "showInUp2 1.5s ease-in-out";
        break;
      case 3:
        convert.style.animation = "messageAfterShow 2s ease-in-out";
        myphone.classList.remove("phonebox");
        break;
      case 4:
        convert.classList.add("hidden");
        
        sms.classList.remove("hidden");
        sms.style.animation = "showInLeft 1.5s ease-in-out";
        break;
      case 6:
        clickPart.style.animation = "clickLink 1.5s ease-in-out";
        break;
      case 7:
        smsBlock.classList.add("hidden");
        aboutPay.classList.remove("hidden");
        aboutPay.style.animation = "showInLeft 1.5s ease-in-out";
        break;
      case 8:
        
        clickBtn.style.animation = "clickBtnEffect 1.5s ease-in-out";
        break;
      case 9:
        aboutPay.style.animation = "showInRight 1.5s ease-in-out";
        
        break;
      case 10:
        aboutPay.classList.add("hidden");
        wallet.classList.remove("hidden");
        wallet.style.animation = "showInLeft 1.5s ease-in-out";
        break;
      case 11:
        payBtn.style.animation = "clickLink 1.5s ease-in-out";
        break;
      case 12:
        wallet.style.animation = "showInRight 1.5s ease-in-out";
        break;
      case 13:
        wallet.classList.add("hidden");
        payedBlock.classList.remove("hidden");
        payedBlock.classList.add("flex");
        payedBlock.style.animation ="showInLeft 1.5s ease-in-out";
        break;
      case 14:
        setPercent(70);
        break;
      case 15:
        setPercent(100);
        payedText.classList.remove("hidden");
        payedText.style.animation ="showInLeft 1s ease-in-out";
        break;
      case 20:
        state = -1;
        payedBlock.classList.add("hidden");
        setPercent(20);
        break;
      default:
        break;
    }
  };

  return (
    <div className="w-[100%] m-0 bg-slate-100">
      

      <div className="flex relative w-full">
        <div className="flex w-full absolute "></div>
      
      <div className="w-full flex flex-row justify-between items-start md:mb-6 min-h-[500px]">
        <div className="mx-12 sm:w-full md:w-[47%] flex flex-col space-y-8 items-start mt-8">
          <h3 className="z-10 sm:text-[24px] md:text-[42px] font-[700] text-slate-900 m-0">
            {t('GOV_FINANCE_SERVICE')}
          </h3>
          
          <ul className="z-10 md:pt-4 sm:text-[20px] md:text-[24px] font-[500] text-slate-400 list-none flex flex-col space-y-9 leading-8">
            <li>{t('GOV_1_1')}</li>

            <li>{t('GOV_1_2')}</li>

            <li>{t('GOV_1_3')}</li>
          </ul>
        </div>
        
        <div className="shadow-2xl pt-4 z-10 sm:hidden md:flex w-[45%] flex-col items-center overflow-hidden">
          
            <div className="slide_1 rounded-md transition delay-150 duration-300 ease-in-out">
              <img alt= '' className="rounded-md" src="/assets/img/smartpay_ui.png"></img>
            </div>
            <div className="slide_2 hidden rounded-md transition delay-150 duration-300 ease-in-out">
              <img alt= '' className="rounded-md" src="/assets/img/smartpay_billing_ui.png"></img>
            </div>
            <div className="slide_3 hidden rounded-md transition delay-150 duration-300 ease-in-out">
              <img alt= '' className="rounded-md" src="/assets/img/smartpay_adliya_ui.png"></img>
            </div>
            
          
        </div>
        
        
      </div>
      </div>
      <div className="bg-cyan-900 py-10">
      <div className="mx-10 ">
      <div className="sm:block md:flex w-full my-5 items-start justify-between">  
        
        <div style={{textAlign:'-webkit-center'}} className="sm:w-auto md:mt-0 md:w-[45%]">
          <div className="w-[300px] h-[570px] rounded-[42px] bg-slate-100 drop-shadow-2xl p-2 myphone">
          <div className="w-full h-full rounded-[38px] bg-white border overflow-hidden">
            
              <div className="w-full h-[80%] flex flex-col justify-center items-center messageBlock">
                <BiSolidMessageError
                  size={80}
                  color="#5081F4"
                  className="convert hidden"
                />

                <div className="w-[90%] flex-col items-start space-y-3 hidden sms">
                  <BiMessageRoundedError size={45} color="#5081F4" />
                  <div className="w-full flex flex-col space-y-2">
                    <p className="text-[17px] text-slate-900">
                      {t('MOB_SMS')}
                    </p>
                    <p className="text-[15px] font-[600]  text-[#5081F4] underline clickPart">
                      https://smartpay.tj/invoice/00442
                    </p>
                  </div>
                </div>
              </div>

              <div className="block w-full h-full rounded-[38px] bg-white hidden payThis">
                <div className="block w-[90%] mx-auto pt-9 items-start space-x-1">
                  <img className="h-[5rem] my-2" src="/assets/img/mvd.webp" alt="mvd" />
                  <p className="text-[15px] font-[600] text-slate-700">
                    {t('G_SERVICE')}
                  </p>
                  <p className="text-[15px] text-slate-700">
                    {t('MOB_G_SERVICE')}
                  </p>
                </div>
                <div className="w-[90%] mx-auto my-10 flex justify-center items-center">
                  <p className="text-[28px] text-slate-500 font-[700]">
                    50.00 TJS
                  </p>
                </div>
                <div className="w-[90%] mt-10">
                  <div className="flex w-[200px] h-[40px] bg-indigo-500 rounded-[10px] bg-slate-100 text-white font-[600] justify-center items-center clickBtn">
                    {t('PAY')}
                  </div>
                </div>
              </div>
              <div className="w-[90%] mx-auto mt-10 hidden wallet">
                <p className="text-[18px] font-[600] text-slate-900">{t('MOB_BANK')}</p>
                <div className="mt-6 w-full space-y-8 items-center">
                  <img
                    width="100%"
                    height={130}
                    src="/credit-card.svg"
                    alt="card"
                  />

                  <div className="block w-full h-[36px] px-3 pt-1">
                    <p className="text-[17px] font-[400] text-slate-600">{t('AMOUNT_TO_PAY')}</p>
                    <p className="text-[24px] font-[800] text-slate-500">50.00 TJS</p>
                  </div>
                  <div className="w-full pt-14">
                    <div className="flex justify-center items-center w-full h-[46px] rounded-[12px] bg-green-600 text-white font-[600] text-center payBtn">
                      {t('PAY')}
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full h-[100%] flex-col justify-center items-center payedBlock hidden">
                <div>
                  <Progress type="circle" percent={percent} />

                  <p className="text-[18px] font-[600] uppercase text-green-500 mt-7 ml-2.5 payedText hidden">
                    {t("PAYED_STAMP")}
                  </p>
                </div>
              </div>
            
          </div>
          </div>
        </div>

        <div className="sm:block sm:mr-10 sm:w-full md:w-[55%] mt-7 md:flex md:flex-col items-start space-y-9">
          <h2 className="z-10 sm:text-[26px] md:text-[34px] font-[700] text-slate-200 m-0">
            {t('GOV_2')}
          </h2>
          <ul className=" z-10 pt-4 sm:text-[20px] md:text-[24px] font-[700] text-slate-400 list-none flex flex-col space-y-7 leading-8">
            <li>{t('GOV_2_1')}</li>
            <li>{t('GOV_2_2')}</li>
            <li>{t('GOV_2_3')}</li>
            </ul>
        </div>
      </div>
      </div>
      </div>
      <div className="w-full py-4">
          <div className="flex justify-center font-bold uppercase text-[#04394f] text-[24px]">
            {t("BANK_PARTNERS")}
          </div>
          <div className="flex flex-wrap justify-center pb-[2rem]">
            <a href="https://amonatbonk.tj" target="blank">
              <img
                alt='Амонатбонк'
                className="sm:h-[32px] md:h-[64px] m-8"
                src="/assets/img/amonatbonk.jpg"
              ></img>
            </a>
            <a href="https://dc.tj" target="blank">
              <img
                alt='Душанбе Сити Бонк'
                className="sm:h-[32px] md:h-[64px] m-8 sm:rounded-[8px] md:rounded-[8px] shadow-2xl"
                src="/assets/img/dc_logo.webp"
              ></img>
            </a>
            <a href="https://alif.tj" target="blank">
              <img
                alt='Алиф Бонк'
                className="sm:h-[32px] md:h-[64px] m-8 sm:rounded-[8px] md:rounded-[8px] shadow-xl"
                src="/assets/img/alif_logo.webp"
              ></img>
            </a>
            <a href="https://eskhata.tj" target="blank">
              <img
                alt='Бонки Эсхата'
                className="sm:h-[32px] md:h-[64px] m-8 sm:rounded-[8px] md:rounded-[8px] shadow-xl"
                src="/assets/img/eskhata.webp"
              ></img>
            </a>
            <a href="https://arvand.tj" target="blank">
              <img
                alt='Бонки Арванд'
                className="sm:h-[32px] md:h-[64px] m-8 sm:rounded-[8px] md:rounded-[8px] shadow-xl"
                src="/assets/img/arvand.webp"
              ></img>
            </a>
            <a href="https://ibt.tj" target="blank">
              <img
                alt='Бонки Байналмиллалии Тоҷикистон'
                className="sm:h-[32px] md:h-[64px] m-8"
                src="/assets/img/ibt_logo.webp"
              ></img>
            </a>
            <a href="https://cbt.tj" target="blank">
              <img
                alt='Коммертсбонк Тоҷикистон'
                className="sm:h-[32px] md:h-[64px] m-8 sm:rounded-[8px] md:rounded-[8px] shadow-2xl"
                src="/assets/img/cbt.webp"
              ></img>
            </a>
            <a href="https://humo.tj/" target="blank">
              <img
                alt='МДО Хумо'
                className="sm:h-[32px] md:h-[64px] m-8 sm:rounded-[8px] md:rounded-[8px] shadow-2xl"
                src="/assets/img/humo_logo.webp"
              ></img>
            </a>
            <a href="https://vasl.tj/" target="blank">
              <img
                alt='НБКО ВАСЛ'
                className="sm:h-[32px] md:h-[64px] m-8 sm:rounded-[8px] md:rounded-[8px] shadow-2xl"
                src="/assets/img/vaslpay.webp"
              ></img>
            </a>
            <a href="https://zudamal.tj/" target="blank">
              <img
                alt='МДО Зудамал'
                className="sm:h-[32px] md:h-[64px] m-8 sm:rounded-[8px] md:rounded-[8px] shadow-2xl"
                src="/assets/img/zml_logo.webp"
              ></img>
            </a>
            <a href="https://mybabilon.tj/" target="blank">
              <img
                alt='MyBabilon'
                className="sm:h-[32px] md:h-[64px] m-8 sm:rounded-[8px] md:rounded-[8px] shadow-2xl"
                src="/assets/img/myBabilon_logo.webp"
              ></img>
            </a>
          </div>
        </div>
      <div className="w-[80%] max-w-[1200px] mx-auto md:mt-10 mb-14">
        <h2 className="text-center z-10 sm:text-[20px] md:text-[30px] font-[600] text-slate-700 m-0">
          {t('SP_INTEGRATION')}
        </h2>
        <div className="flex flex-wrap justify-center">
        <Card className="m-4 sm:w-full md:w-[250px]">
          <div className="flex m-auto items-center">
            <img className="sm:h-[2rem] md:h-[3rem]" src="/assets/img/mvd.webp" alt="mvd" />
            <div className="m-auto px-2 items-center text-slate-600 text-[12px]">{t('MVD')}</div>
          </div>
        </Card>

        <Card className="m-4 sm:w-full md:w-[250px]">
          <div className="flex m-auto items-center">
            <img className="sm:h-[2rem] md:h-[3rem]" src="/assets/img/adliya.png" alt="adliya" />
            <div className="m-auto px-2 items-center text-slate-600 text-[12px]">{t('ADLIYA')}</div>
          </div>
        </Card>

        <Card className="m-4 sm:w-full md:w-[250px]">
          <div className="flex m-auto items-center">
            <img className="sm:h-[2rem] md:h-[3rem]" src="/assets/img/dushanbe_logo.webp" alt="adliya" />
            <div className="m-auto px-2 items-center text-slate-600 text-[12px]">{t('MERIYA')}</div>
          </div>
        </Card>

        <Card className="m-4 sm:w-full md:w-[250px]">
          <div className="flex m-auto items-center">
            <img className="sm:h-[2rem] md:h-[3rem]" src="/assets/img/logo_tj_standard.webp" alt="adliya" />
            <div className="m-auto px-2 items-center text-slate-600 text-[12px]">{t('TJSTANDARD')}</div>
          </div>
        </Card>

        <Card className="m-4 sm:w-full md:w-[250px]">
          <div className="flex m-auto items-center">
            <img className="sm:h-[2rem] md:h-[3rem]" src="/assets/img/tj_gerb.svg" alt="adliya" />
            <div className="m-auto px-2 items-center text-slate-600 text-[12px]">{t('RNI')}</div>
          </div>
        </Card>

        <Card className="m-4 sm:w-full md:w-[250px]">
          <div className="flex m-auto items-center">
            <img className="sm:h-[2rem] md:h-[3rem]" src="/assets/img/dushanbe_logo.webp" alt="adliya" />
            <div className="m-auto px-2 items-center text-slate-600 text-[12px]">{t('MED')}</div>
          </div>
        </Card>

        <Card className="m-4 sm:w-full md:w-[250px]">
          <div className="flex m-auto items-center">
            <img className="sm:h-[2rem] md:h-[3rem]" src="/assets/img/dushanbe_logo.webp" alt="adliya" />
            <div className="m-auto px-2 items-center text-slate-600 text-[12px]">{t('MAORIF')}</div>
          </div>
        </Card>

        </div>
      </div>
    </div>
  );
}

export default Government;
